import {ConnectElementImportKeys} from '../../connect/ConnectJSInterface/ConnectElementList';
import {getCurrentScriptUrlContext} from '../../utils/getCurrentScriptUrlContext';
import {embeddedComponentParamName} from '../buildUILayerFrame';

export const buildStripeJsLayerFrame = async (
  frameId: string,
  overlayZIndex: number,
  connectElement: ConnectElementImportKeys,
): Promise<Window> => {
  const {absoluteFolderPath} = getCurrentScriptUrlContext();
  const url = new URL(
    // eslint-disable-next-line camelcase
    `${absoluteFolderPath}/stripejs_layer_${__webpack_hash__}.html`,
  );

  const searchParams = new URLSearchParams();
  searchParams.set('stripejs_layer_id', frameId);
  searchParams.set(embeddedComponentParamName, connectElement);
  url.hash = searchParams.toString();

  const iframe = document.createElement('iframe');

  iframe.setAttribute('data-stripejs-layer', 'true');
  iframe.name = frameId;
  iframe.style.position = 'fixed';
  iframe.style.height = '100%';
  iframe.style.width = '100%';
  iframe.style.top = '0px';
  iframe.style.left = '0px';

  // iframe style resets
  iframe.style.background = 'transparent';
  iframe.style.border = 'none';
  iframe.style.borderRadius = '0px';
  iframe.style.borderWidth = '0px';
  iframe.style.boxSizing = 'content-box'; // This one is important for positioning and sizing
  iframe.style.cursor = 'auto';
  iframe.style.margin = '0px';
  iframe.style.maxHeight =
    'calc(100% - env(safe-area-inset-top, 0px) - env(safe-area-inset-bottom, 0px))';
  iframe.style.maxWidth = 'none';
  iframe.style.minHeight = 'none';
  iframe.style.minWidth = 'none';
  iframe.style.opacity = '1';
  iframe.style.outline = 'none';
  iframe.style.padding =
    'env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px)';
  iframe.style.pointerEvents = 'auto';
  iframe.style.transform = 'none';
  iframe.style.transition = 'none';
  iframe.style.userSelect = 'auto';
  iframe.style.verticalAlign = 'baseline';
  iframe.style.visibility = 'visible';

  // Hide scrollbar
  iframe.style.overflow = 'hidden';
  iframe.scrolling = 'no';

  iframe.style.zIndex = overlayZIndex.toString();
  iframe.src = url.toString();

  window.document.body.append(iframe);
  return new Promise((resolve, reject) => {
    iframe.onload = () => {
      if (!iframe.contentWindow) {
        reject(new Error(`Accessory layer ${frameId} failed to load`));
        return;
      }
      resolve(iframe.contentWindow);
    };
    iframe.onerror = (error) => {
      reject(error);
    };
  });
};
